<template>
  <ul class="list-unstyled shadow-sm">
    <li
      v-for="(item,index) in navList"
      :key="index"
      class="text-center bg-white px-0"
    >
      <router-link
        class="p-3 text-decoration-none"
        :class="$style[`nav-link`]"
        :active-class="$style[`active`]"
        :to="item.to"
      >
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'MemberNav',
  setup () {
    const navList = ref([
      {
        to: '/member/detail',
        title: '會員資訊'
      },
      {
        to: '/member/password',
        title: '修改密碼'
      },
      {
        to: '/member/orders',
        title: '進行中訂單'
      },
      {
        to: '/member/orders/complete',
        title: '已完成訂單'
      },
      {
        to: '/member/bonus/added',
        title: '購物金入賬列表'
      },
      {
        to: '/member/bonus/used',
        title: '購物金使用列表'
      }
    ])

    return { navList }
  }
}
</script>
<style lang="scss" module>
.nav-link {
  display: block;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link.active {
  background-color: $primary;
  color: $white;
  font-weight: bold;
}
</style>
